import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";

const Main = () => {
  const { lang } = useSelector((state) => state.lang);
  const [checkModal, setCheckModal] = useState(false);

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem("hasSeenModal");

    if (!hasSeenModal) {
      setCheckModal(true);
      sessionStorage.setItem("hasSeenModal", "true");

      const timer = setTimeout(() => {
        setCheckModal(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      {checkModal && (
        <div
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[120] flex justify-center items-center w-full h-full bg-[#0000009a]"
          onClick={() => setCheckModal(false)}
        >
          <div className="relative flex flex-col items-center justify-center h-full p-4 w-full">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow-sm dark:bg-gray-700">
              {/* <!-- Modal header --> */}
              <div className="flex items-center justify-between gap-[20px] p-4 md:p-5 rounded-t dark:border-gray-600 border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {lang === "ru"
                    ? "Данная платформа находится в тестовом режиме."
                    : "Ushbu platforma test rejimda ishlamoqda"}
                </h3>
                <button
                  type="button"
                  className="text-white cursor-pointer bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setCheckModal(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Header />
      <main>{<Outlet />}</main>
      <Footer />
    </>
  );
};

export default Main;
